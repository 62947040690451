<template>
  <div class="window">
    <div class="head">
      <div class="tabs-group">
        <draggable
          :list="this.$store.state.draggableTab.tabs"
          v-bind="dragOptions"
          :style="tabsWidth"
        >
          <transition-group class="tabs" name="list-complete">
            <template>
              <div
                v-for="tab in this.$store.state.draggableTab.tabs"
                :key="tab.id"
                :class="[active(tab.id), 'tab']"
                v-b-tooltip.hover.bottom="tab.name"
                @mousedown="$store.commit('draggableTab/CHANGE_TAB', tab.id)"
                @click="relocate(tab)"
              >
                <span style="margin-left: 7px; font-size: 13px" class="text">{{
                  tab.name
                }}</span>
                <div
                  class="close-button"
                  @click.stop="
                    $store.commit('draggableTab/CLOSE_TAB', tab.id),
                      backPath(tab.id)
                  "
                ></div>
              </div>
            </template>
          </transition-group>
        </draggable>
      </div>
    </div>
    <slot name="content"><div class="content"></div></slot>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: { draggable },
  computed: {
    active() {
      return (id) =>
        id == this.$store.state.draggableTab.currentTabId && "-active";
    },
    dragOptions() {
      return {
        animation: 250,
        disabled: false,
        ghostClass: "-dragging",
      };
    },
    tabsWidth() {
      return {
        maxWidth: this.$store.state.draggableTab.tabs.length * 190 + "px",
        width: "100%",
        minWidth: "0px",
        transition: "max-width 0.2s",
      };
    },
  },
  methods: {
    beforeCloseTab(e) {
      e.stopPropagation();
    },
    backPath(value) {
      const pathNumber = value - 1;
      this.$store.state.draggableTab.tabs.forEach((tab) => {
        if (tab.id == pathNumber) {
          this.$router.push({ path: tab.path, query: { filter: tab.query } });
        }
      });
    },
    relocate(tab) {
      if (this.$route.path !== tab.path) {
        this.$router.push({ path: tab.path, query: { filter: tab.query } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #fff;
$gray-dark: #dee1e6;
$gray-light: #e7eaef;
.window {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  > .content {
    min-height: 0;
    height: 100%;
  }
}

.head {
  background-color: #9dc2ff;
  display: flex;
  overflow: hidden;
}

.menu {
  min-height: 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-dark;

  > .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
  }
}

.tabs-group {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  min-width: 0px;

  > .add-button {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 8px;
    margin: 0 4px 2px;
    border-radius: 100%;
    transition: background-color 0.5s;

    &:hover {
      &::before,
      &::after {
        background-color: #fff;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 2px;
      height: 14px;
      background-color: #2979ff;
    }

    &::before {
      transform: rotate(90deg);
    }
  }
}

.tabs {
  display: inline-flex;
  align-items: flex-end;
  box-sizing: border-box;
  line-height: 1;
  position: relative;
  z-index: 1;
  user-select: none;
  width: 100%;
}

.tab {
  position: relative;
  background-color: #2979ff;
  color: #fff;
  padding: 8px 12px;
  //   border-radius: 10px 10px 0 0;
  font-size: 12px;
  display: block;
  max-width: 160px;
  transition: background-color 0.2s;
  border-left: 1px solid #9dc2ff;
  cursor: default;
  position: relative;
  margin-right: -1px;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background-color: #5c6bc0;
    &::before {
      background-color: #5c6bc0;
      right: 0;
    }
  }

  &.-active {
    background-color: #5c6bc0;

    &::before {
      background-color: #5c6bc0;
      right: 0;
    }
  }

  &.-dragging {
    opacity: 0;
  }

  &::after {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    background-color: #2979ff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: -1;
  }

  &::before {
    content: "";
    display: block;
    height: 20px;
    width: 30px;
    position: absolute;
    right: 1px;
    background-color: #2979ff;
    transition: background-color 0.2s;
  }

  > .close-button {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    border-radius: 100%;
    padding: 4px;
    position: absolute;
    right: 8px;

    &:hover {
      &::before,
      &:after {
        background-color: #000;
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 2px;
      height: 12px;
      background-color: #fff;
    }

    &::before {
      transform: rotate(90deg);
    }
  }
}

.tab-content {
  display: none;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;

  &.-active {
    display: block;
  }
}
</style>
