<template>
  <div @click="clickToCell">
    <layout-vertical>
      <router-view />
      <app-customizer v-if="showCustomizer" slot="customizer" />
    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  mounted() {
    this.clickToCell();
  },
  methods: {
    clickToCell() {
      document.addEventListener("click", (event) => {
        const textToCopy = event.target.textContent;
        document.addEventListener("keydown", handleKeyDown);
        function handleKeyDown(e) {
          if (
            (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
            e.key === "c"
          ) {
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {})
              .catch((error) => {});
            document.removeEventListener("keydown", handleKeyDown);
          }
        }
      });
    },
  },
};
</script>
