<template>
  <b-modal
    id="driver_closed_order"
    title="Закрытые заказы"
    @show="getData"
    @shown="resizeable"
    centered
    ok-only
    size="lg"
    no-close-on-backdrop
  >
    <div class="m-0" style="overflow-x: scroll">
      <b-table
        striped
        small
        id="myTables"
        bordered
        selectable
        selected-variant="primary"
        select-mode="single"
        responsive="sm"
        :items="ordersHistory"
        :fields="fields"
        head-row-variant="secondary"
      >
        <template #cell(checkbox)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          ></b-form-checkbox>
        </template>
        <template #cell(create_user)="data">{{
          data.item.create_user ? data.item.create_user.login : ""
        }}</template>
        <template #cell(to_addresses)="data">
          <span v-for="(to_address, idx) in data.item.to_addresses" :key="idx">
            {{
              to_address
                ? idx === data.item.to_addresses.length - 1
                  ? to_address.name
                  : to_address.name + "; "
                : ""
            }}
          </span>
        </template>
        <template #cell(performer_name)="data">
          {{
            data.item.performer == null
              ? ""
              : data.item.performer.last_name +
                " " +
                data.item.performer.first_name
          }}
        </template>
        <template #cell(performer_phone)="data">
          {{ data.item.performer ? data.item.performer.phone : "" }}
        </template>
        <template #cell(performer_login)="data">
          {{ data.item.performer ? data.item.performer.login : "" }}
        </template>
        <template #cell(model)="data">
          {{ data.item.model + " (" + data.item.model_editor_login + ")" }}
        </template>
        <template #cell(performer_transport)="data">
          {{
            data.item.performer == null || data.item.performer.transport == null
              ? ""
              : data.item.performer.transport.model +
                `(${data.item.performer.transport.car_number})`
          }}
        </template>
        <template #cell(distance)="data">
          {{
            data.item.distance != null
              ? data.item.distance.toFixed(1) + "км"
              : ""
          }}
        </template>
        <template #cell(allowances)="data">
          <span v-for="(all, index) in data.item.allowances">
            {{
              index === data.item.allowances.length - 1
                ? all.allowance
                : all.allowance + ", "
            }}
          </span>
        </template>
        <template #cell(report)="data">
          <router-link
            :to="{
              name: 'report/assigment',
              query: { order_id: data.item.id },
            }"
          >
            <div style="color: blue">(***)</div>
          </router-link>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["driverId"],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      ordersHistory: [],
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "60px" } },
        {
          key: "order_id",
          label: "Id заказа",
          sortable: true,
          thStyle: { width: "80px" },
        },
        {
          key: "created_at",
          label: "Время изменения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "create_user",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "client_phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "price",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "from_address.name",
          label: "Откуда",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "to_addresses",
          label: "Куда",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_phone",
          label: "Номер водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "division",
          label: "Подразделения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "tariff",
          label: "Тариф",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "allowances",
          label: "Надбавки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Время поступления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "Запросы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "distance",
          label: "Растояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_time",
          label: "На время",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  methods: {
    resizeable() {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(
        document.querySelectorAll("table th"),
        function (th) {
          th.style.position = "relative";
          var grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
        }
      );
      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });
      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
    getData() {
      if (!this.driverId) {
        return
      }
      this.$http
        .get(`drivers/${this.driverId}/history-orders`)
        .then((res) => {
          this.ordersHistory = res.data.data
        })
        .catch((err) => {
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
#driver_active_order {
  z-index: 9999;
}
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: auto;
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
  }
}
</style>